import React from 'react';

interface ConsoleProps {
  isOpen: boolean;
  onClose: () => void;
}

const defaultText = 'Hola Multileaf, estoy interesado en sus servicios de desarrollo de software.';
const phoneNumber = '51951704068';
const whatsappBaseURL = `https://wa.me/${phoneNumber}?text=${defaultText}`;

function Console({ isOpen, onClose }: ConsoleProps): React.ReactElement {
  if (!isOpen) {
    return (<div>&nbsp;</div>);
  }

  return (
    <div className="fixed z-50 bg-black/60 w-screen h-screen top-0 left-0 flex">
      <div className="bg-slate-800 m-auto relative w-96 h-52 rounded">
        <div className="bg-gray-800 rounded-t flex justify-end p-2">
          <button className="rounded-full bg-red-600 w-4 h-4 p-0 flex justify-center items-center text-gray-800 text-xs" type="button" onClick={onClose}>
            x
          </button>
        </div>
        <div className="p-6 text-green-700 text-sm">
          <div className="mb-4">
            <span className="text-yellow-600">$ </span>
            <span className="text-green-600">show </span>
            <span className="text-white">info</span>
            <span className="blink-underscore inline-block">_</span>
          </div>
          <div className="ml-1">
            <span className="inline-block">
              <span className="text-orange-500">&gt; Teléfono: </span>
              <span className="text-white">+51 951 704 068</span>
            </span>
          </div>
          <div className="ml-1">
            <span className="inline-block">
              <span className="text-orange-500">&gt; Correo: </span>
              <span className="text-white">jhuamani@multileaf.software</span>
            </span>
          </div>
          <div className="text-center mt-4">
            <span className="inline-block">
              <a href={whatsappBaseURL} className="text-orange-500 underline underline-offset-4" target="_blank" rel="noreferrer">
                Whatsapp
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Console;
