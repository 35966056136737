import React, { useCallback, useState } from 'react';
import BannerImage from './assets/images/banner-1.jpg';
import './App.css';
import Console from './components/Console';

function App(): React.ReactElement {
  const [isOpenConsole, setIsOpenConsole] = useState(false);

  const handleOpenConsole = useCallback(() => {
    setIsOpenConsole(true);
  }, [setIsOpenConsole]);

  const handleCloseConsole = useCallback(() => {
    setIsOpenConsole(false);
  }, [setIsOpenConsole]);

  return (
    <div id="app" className="font-mono container mx-auto relative">
      <header className="flex items-center justify-center lg:justify-between absolute top-0 z-10 w-full">
        <div
          className="p-6 bg-black/90 text-center font-black text-green-700"
        >
          <span className="inline-block typing-text">
            Multileaf.Software
          </span>
          <span className="blink-underscore inline-block">_</span>
        </div>
        <div className="hidden lg:block">
          <div className="flex items-center justify-between py-6 md:justify-end md:space-x-10 p-6">
            {/* <div className="menu-item text-base font-medium text-gray-500 hover:text-green-900"> */}
            {/*   Inicio */}
            {/*   <span className="blink-underscore inline-block">_</span> */}
            {/* </div> */}
            {/* <div className="menu-item text-base font-medium text-gray-500 hover:text-green-900"> */}
            {/*   Nosotros */}
            {/*   <span className="blink-underscore inline-block">_</span> */}
            {/* </div> */}
            {/* <div className="menu-item text-base font-medium text-gray-500 hover:text-green-900"> */}
            {/*   Servicios */}
            {/*   <span className="blink-underscore inline-block">_</span> */}
            {/* </div> */}
            {/* <div className="menu-item text-base font-medium text-gray-500 hover:text-green-900"> */}
            {/*   Proyectos */}
            {/*   <span className="blink-underscore inline-block">_</span> */}
            {/* </div> */}
            <div className="text-base font-medium text-gray-500 hover:text-green-900">
              <button type="button" onClick={handleOpenConsole} className="menu-item ">
                Contactanos
                <span className="blink-underscore inline-block">_</span>
              </button>
            </div>
          </div>
        </div>
      </header>

      <div className="bg-black relative">
        <div className="relative">
          <img src={BannerImage} alt="" className="w-full aspect-video bg-black" />
          <div className="absolute top-2/4 p-6 bg-black/50 text-white w-full text-center text-2xl">
            Expertos en servicios de tecnología
          </div>
        </div>
        <div className="relative lg:absolute bottom-0 grid text-white gap-4 grid-cols-1 lg:grid-cols-3 w-full text-center">
          <div className="p-12 bg-white/5 lg:bg-black/50 relative col-start-2">
            <h1>Desarrollo de Software</h1>
            <div className="text-left">
              <ul className="list-disc px-8">
                <li>ERP de gestión</li>
                <li>Software a medida</li>
                <li>Aplicaciones Web</li>
                <li>Aplicaciones Mobile</li>
                <li>E-commerce</li>
              </ul>
            </div>
          </div>
          {/* <div className="p-12 bg-white/5 lg:bg-black/50 relative"> */}
          {/*   <h1>Desarrollo de Software</h1> */}
          {/*   <div className="text-left"> */}
          {/*     <ul className="list-disc px-8"> */}
          {/*       <li>ERP de gestión</li> */}
          {/*       <li>Software a medida</li> */}
          {/*       <li>Aplicaciones Web</li> */}
          {/*       <li>Aplicaciones Mobile</li> */}
          {/*       <li>E-commerce</li> */}
          {/*     </ul> */}
          {/*   </div> */}
          {/* </div> */}
          {/* <div className="p-12 bg-white/5 lg:bg-black/50 relative"> */}
          {/*   <h1>Desarrollo de Software</h1> */}
          {/*   <div className="text-left"> */}
          {/*     <ul className="list-disc px-8"> */}
          {/*       <li>ERP de gestión</li> */}
          {/*       <li>Software a medida</li> */}
          {/*       <li>Aplicaciones Web</li> */}
          {/*       <li>Aplicaciones Mobile</li> */}
          {/*       <li>E-commerce</li> */}
          {/*     </ul> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
      </div>
      <div className="inset-x-0 bottom-0 h-16 text-center mt-6 text-white">
        Multileaf Software &copy; 2022
      </div>
      <Console isOpen={isOpenConsole} onClose={handleCloseConsole} />
    </div>
  );
}

export default App;
